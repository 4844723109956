<template>
    <div>
  <div v-html="filesHTML"></div>
    </div>
</template>

<script>
export default {
    name: "Files",
    props: ['filesHTML'],
}
</script>

<style scoped>

</style>