export default {
    ADAPTER: 1,
    PITCHED: 2,
    METAL_BUILDING_CURB: 3,
    STRAIGHT_CURB: 4,
    EXHAUST_FAN_CURB: 5,
    BURGLAR_BAR: 6,
    ADJUSTABLE_PITCHED_CURB: 7,
    CUSTOM: 8,
    ISO_RAIL: 9,
    MISCELLANEOUS: 10,
    RTU_DOUBLE_WALL: 11,
    ERV_COMBO: 12,
    CATWALK: 13,
    EQUIPMENT_RAILS: 14,
    CLIPS: 15,
    MULTI_ZONE_ADAPTERS: 16,
    ISO_CURB: 17,
    SCREEN: 18,
    ISO_ADAPTER: 19,
    EXTENSION: 20,
    STRADAPTER: 21,
    VERT_CURB: 22,
    VRF_STRAIGHT_CURB: 23,
    VRF_PITCHED_CURB: 24,



    LONG_SIDE_PEAK: 1,
    SHORT_SIDE_PEAK: 2,
    LONG_SIDE_EAVE: 3,
    SHORT_SIDE_EAVE: 4,
    MULTI_PITCH_PEAK_EAVE: 5,

    CURB_TYPES_HISTORICAL: [
        [7, 'Adjustable Pitched Curb'],
        [6, 'Burglar Bar'],
        [13, 'Catwalk'],
        [15, 'Clips'],
        [1, 'Curb Adapter'],
        [20, 'Curb Extension'],
        [8, 'Custom'],
        [12, 'ERV Combo'],
        [14, 'Equipment Rails'],
        [5, 'Exhaust Fan Curb'],
        [19, 'Isolation Curb Adapter'],
        [9, 'Isolation Rail'],
        [17, 'Isolation Curb'],
        [3, 'Metal Building Curb'],
        [10, 'Miscellaneous'],
        [16, 'Multi-Zone Adapters'],
        [2, 'Pitched Curb'],
        [11, 'RTU Double Wall'],
        [18, 'Screen'],
        [21, 'Stradapter'],
        [4, 'Straight Curb']
    ],

    CURB_TYPES: [
        [7, 'Adjustable Pitched Curb'],
        [6, 'Burglar Bar'],
        [15, 'Clips'],
        [1, 'Curb Adapter'],
        [20, 'Curb Extension'],
        [8, 'Custom'],
        [12, 'ERV Combo'],
        [14, 'Equipment Rails'],
        [5, 'Exhaust Fan Curb'],
        [19, 'Isolation Curb Adapter'],
        [9, 'Isolation Rail'],
        [17, 'Isolation Curb'],
        [3, 'Metal Building Curb'],
        [10, 'Miscellaneous'],
        [16, 'Multi-Zone Adapters'],
        [2, 'Pitched Curb'],
        [21, 'Stradapter'],
        [4, 'Straight Curb'],
        [22, 'Vertical Curb'],
        [23, 'VRF Straight Curb'],
        [24, 'VRF Pitched Curb']



    ],

    CURB_GAUGES: [
        [12, '12 Ga'],
        [14, '14 Ga'],
        [16, '16 Ga'],
        [18, '18 Ga']
    ],

    SIDE_TYPES: [
        [0, 'Straight'],
        [1, 'Pitched Short Side'],
        [2, 'Pitched Long Side'],
        [3, 'Metal Building Pitched Short Side'],
        [4, 'Metal Building Pitched Long Side'],
        [5, 'Adjustable Pitched Side'],
        [9, 'Multi Pitched'],
        [10, 'Knockdown'],
    ],

    EXTENSION_TYPE: [
        [1, 'Adapter'],
        [2, 'Straight (with wood nailer)'],
        [3, 'Straight (3" Ins, full divider walls, no wood nailer)'],
        [5, 'Fan Curb'],
        [7, 'ISO Curb'],
        [9, 'Equipment Rails'],
    ],

    PITCHES: [
        // [0, 'No Pitch'],
        [1, '1/4 / 12 (1.19 degrees)'],
        [2, '1/2 / 12 (2.39 degrees)'],
        [3, '1/12 (4.76 degrees)'],
        [4, '2/12 (9.46 degrees)'],
        [5, '3/12 (14.04 degrees)'],
        [6, '4/12 (18.43 degrees)'],
        [7, '5/12 (22.62 degrees)'],
        [8, '6/12 (26.57 degrees)'],
        [9, 'Not Shown']
    ],

    BURGLAR_SPACING: [
        [4, '4 in'],
        [5, '5 in'],
        [6, '6 in Standard'],
        [8, '8 in'],
        [9, '9 in'],
        [10, '10 in'],
        [11, '11 in'],
        [12, '12 in']
    ],
    BURGLAR_REBAR: [
        [0, '1/2 in Standard'],
        [1, '1 in Oversized'],
        [2, 'Not Shown']
    ],
    BURGLAR_FRAME: [
        [0, 'Sheet Metal Frame'],
        [1, 'Angle Iron Frame']
    ],

    INCHES_FRACTIONS: [
        [0, '0'],
        [1, '1/8'],
        [2, '1/4'],
        [3, '3/8'],
        [4, '1/2'],
        [5, '5/8'],
        [6, '3/4'],
        [7, '7/8']
    ],

    RTU_ACCESSORIES: [
        [270, 'No Accessories'],
        [271, 'Economizer - Factory'],
        [272, 'Power Exhaust - Factory'],
        [273, 'Economizer - 3rd Party'],
        [274, 'Power Exhaust - 3rd Party'],
        [275, 'RTU Screen'],
    ],

    CURB_DEPTH: [
        [1, '03'],
        [2, '10'],
        [3, '12'],
    ],

    TOP_OFFSET: [
        [1, '01'],
        [2, '12'],
        [3, '24'],
    ]
}