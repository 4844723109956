<template>
    <div>
        <input type="button" class="cdi_button btn btn-default" value="New Bill To" @click="newBillTo">
        <form>
            <div class="panel panel-default spacing_top" v-show="showForm">
                <div class="panel-heading"></div>
                <div class="row panel-body">
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Label</label>
                        <input type=text v-model="formFields.bill_to_label.val" class="form-control">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Method</label>
                        <select class="form-control" v-model="formFields.send_method.val">
                            <option value="1">Email</option>
                            <option value="2">Fax</option>
                            <option value="3">Mail</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>First Name</label>
                        <input type="text" v-model="formFields.first_name.val" class="form-control">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Last Name</label>
                        <input type="text" v-model="formFields.last_name.val" class="form-control">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Email (Invoice)</label>
                        <input type="text" v-model="formFields.email.val" class="form-control">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Email (Past Due)</label>
                        <input type="text" v-model="formFields.email_past_due.val" class="form-control">
                    </div>
                  <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Email (Monthly Statements)</label>
                    <input type="text" v-model="formFields.email_monthly_statement.val" class="form-control">
                  </div>
                    <div class="col-sm-6 col-xs-12 form-group">
                        <label>Address</label>
                        <textarea v-model="formFields.address.val" class="form-control"></textarea>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>City</label>
                        <Typeahead cdi_type="city" v-bind:ID.sync="formFields.city.val" />
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label >State</label>
                        <select class="form-control" v-model="formFields.state.val">
                            <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Zip</label>
                        <input type="text" v-model="formFields.zip.val" class="form-control">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Phone</label>
                        <input type="text" v-model="formFields.phone.val" class="form-control">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Fax</label>
                        <input type="text" v-model="formFields.fax.val" class="form-control">
                    </div>
                    <div class="col-sm-6 col-xs-12 form-group">
                        <label>Notes</label>
                        <textarea v-model="formFields.notes.val" class="form-control"></textarea>
                    </div>
                    <div class="col-xs-12">
                        <div class="pull-right">
                            <label>
                                <input type="checkbox" v-model="formFields.copy_bill_to.val" value="1"> Save As Copy
                            </label>
                        </div>
                        <div>
                            <input class="cdi_button btn btn-default" type="button" value="Save Bill To" @click="save">
                            <input type="button" value="Cancel" class="cdi_button btn btn-default" @click="clearForm">
                        </div>
                    </div>
                </div>
            </div>

        </form>
        <div class="table-responsive results">
            <table class='cdi_button table'>
                <thead>
                <tr>
                    <th></th>
                    <th class='mobile_hide'>Label (method)</th>
                    <th>Name</th>
                    <th>Email (invoice/past due/monthly statement)</th>
                    <th>Address</th>
                    <th>Phone/Fax</th>
                    <th>Notes</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="billTo in billTos" v-bind:key="billTo.company_bill_to_id">
                    <td style="min-width: 100px;">
                        <span class="glyphicon glyphicon-pencil cdi_link" @click="load(billTo)"></span>
                        <span class="glyphicon glyphicon-trash cdi_link spacing_left" @click="deleteConfirm(billTo.company_bill_to_id)"></span>
                    </td>
                    <td class='mobile_hide'>{{ billTo.bill_to_label }} ({{ billTo.send_method_txt }})</td>
                    <td>{{ billTo.first_name }} {{ billTo.last_name }}</td>
                    <td>{{ billTo.email }} / {{ billTo.email_past_due }} / {{billTo.email_monthly_statement}}</td>
                    <td>{{ billTo.address }}<br>{{ billTo.city }}, {{ billTo.state }} {{ billTo.zip }}</td>
                    <td>{{ billTo.phone }} / {{ billTo.fax }}</td>
                    <td>{{ billTo.notes }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <ConfirmBox
            v-if="confirmBox"
            :heading="'Delete Bill To Address?'"
            :body="'Would you like to delete this Bill To address?'"
            @close="deleteBillTo"
        />
    </div>

</template>

<script>
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import ConfirmBox from '@/components/utils/ConfirmBox';
import stateCache from '@/cache/state.cache.js';
import {store} from "@/store/BusinessObject.store";
import helpers from "@/store/helpers";
export default {
    name: "BillTo",
    props: ['billTos'],
    components: {
        Typeahead,
        ConfirmBox,
    },
    data() {
        return {
            showForm: false,
            formFields: {
                company_bill_to_id: { val: '', qsField: '[company_bill_to_id]' },
                bill_to_label: { val: '', qsField: '[bill_to_label]' },
                send_method: { val: 1, qsField: '[send_method]' },
                first_name: { val: '', qsField: '[first_name]' },
                last_name: { val: '', qsField: '[last_name]' },
                email: { val: '', qsField: '[email]' },
                email_past_due: { val: '', qsField: '[email_past_due]' },
                email_monthly_statement: { val: '', qsField: '[email_monthly_statement]' },
                address: { val: '', qsField: '[address]' },
                city: { val: '', qsField: '[city]' },
                state: { val: '', qsField: '[state]' },
                zip: { val: '', qsField: '[zip]' },
                phone: { val: '', qsField: '[phone]' },
                fax: { val: '', qsField: '[fax]' },
                notes: { val: '', qsField: '[notes]' },
                copy_bill_to: { val: '', qsField: '[copy_bill_to]' },
            },
            confirmBox: false,
            deleteID: 0,
            cache: {
                stateCache,
            },
        }
    },
    methods: {
        clearForm: function () {
            this.showForm = false;
            for (const name in this.formFields){
                this.formFields[name].val = '';
            }
        },
        save: function() {
            store.api('save_bill_to', helpers.getQSPairs(this.formFields), 'object.data')
                .then(() => this.clearForm());
        },
        load: function(billTo) {
            for (const name in this.formFields){
                this.formFields[name].val = billTo[name];
            }
            this.showForm = true;
        },
        deleteConfirm: function(id) {
            this.deleteID = id;
            this.confirmBox = true;
        },
        deleteBillTo: function(confirm) {
            if (confirm){
                store.api('del_bill_to', {'params': this.deleteID}, 'object.data');
            }
            this.confirmBox = false;
            this.deleteID = 0;
        },
        newBillTo: function() {
            this.clearForm();
            this.showForm = true;
        }
    },
}
</script>

<style scoped>

</style>