<template>
    <div>
        <h2>Branches</h2>
        <div class="table-responsive" id="dashboard_table">
            <table class='table'>
                <thead>
                <tr>
                    <th class="sortable" @click="sort('branch_name')">Branch</th>
                    <th class="sortable" @click="sort('ca.state')">State</th>
                    <th class="number sortable" @click="sort('ytd_sales')">Sales</th>
                    <th class="number sortable" @click="sort('ytd_quotes')">Quotes</th>
                    <th class="number sortable" @click="sort('customer_count')">Customers</th>
                    <th class="number sortable" @click="sort('prospect_count')">Prospects</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="branch in data.branches" v-bind:key="branch.branch_id">
                    <td><router-link :to="`/branch/${branch.branch_id}`" :title="`${branch.address}\n${branch.city}, ${branch.state} ${branch.zip}`">{{ branch.branch_name }} {{ branch.branch_type_desc }}</router-link></td>
                    <td>{{ branch.state }}</td>
                    <td class='number'>${{ branch.ytd_sales }}</td>
                    <td class='number'>${{ branch.ytd_quotes }}</td>
                    <td class='number'>{{ branch.customer_count }}</td>
                    <td class='number'>{{ branch.prospect_count }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "DashboardBranches",
    props: ['data'],
    methods: {
        sort: function(column) {
            this.$emit('sort', column);
        },
    }
}
</script>

<style scoped>

</style>