<template>
    <div>
        <form>
            <div class="panel panel-default spacing_top">
                <div class="panel-heading"></div>
                <div class="row panel-body">
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Branch Name</label>
                        <input type="text" v-model="formFields.branchName.val" class="form-control">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Address</label>
                        <input type="text" v-model="formFields.address.val" class="form-control">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>City</label>
                        <input type="text" v-model="formFields.city.val" class="form-control">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>State</label>
                        <select class="form-control" v-model="formFields.state.val">
                            <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Zip</label>
                        <input type="text" v-model="formFields.zip.val" class="form-control">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Branch Type</label>
                        <select class="form-control" v-model="formFields.branchType.val">
                            <option value="0">None</option>
                            <option v-for="[id, type] in cache.branchCache.TYPES" v-bind:key="id" :value="id">{{ type }}</option>
                        </select>
                    </div>
                    <div class="col-xs-12">
                        <input class="cdi_button btn btn-default" type="button" value="Save Branch" @click="save">
                        <input type="button" value="Cancel" class="cdi_button btn btn-default" @click="clearForm">
                    </div>
                </div>
            </div>
        </form>
    </div>

</template>

<script>
import stateCache from '@/cache/state.cache.js';
import branchCache from '@/cache/branch.cache.js';
import {store} from "@/store/BusinessObject.store";
import helpers from "@/store/helpers";
export default {
    name: "NewBranch",
    data() {
        return {
            formFields: {
                branchName: { val: '', qsField: '[branch_name]' },
                address: { val: '', qsField: '[address]' },
                city: { val: '', qsField: '[city]' },
                state: { val: '', qsField: '[state]' },
                zip: { val: '', qsField: '[zip]' },
                branchType: { val: '', qsField: '[branch_type]' },
            },
            cache: {
                stateCache,
                branchCache,
            }
        }
    },
    methods: {
        save: function() {
            store.api('save_branch', helpers.getQSPairs(this.formFields), 'object.data')
                .then(() => this.clearForm());
        },
        clearForm: function() {
            this.formFields.branchName.val = '';
            this.formFields.address.val = '';
            this.formFields.city.val = '';
            this.formFields.state.val = '';
            this.formFields.zip.val = '';
            this.formFields.branchType.val = '';
        }
    },
}
</script>

<style scoped>

</style>