export default {
    CUSTOMER_LEVELS_LEGACY: [
        [1, 'Platinum'],
        [2, 'Gold'],
        [3, 'Silver'],
        [4, 'Bronze'],
        [5, 'List'],
        [6, 'Contractor'],
        [14, 'Auer'],
        [19, 'Carrier West'],
        [20, 'Vendor'],
        [21, 'Temp'],
        [22, 'No Pricing'],
        [23, 'Elite'],
        [24, 'Transit 2'],
        [25, 'Transit 1'],
        [26, 'Base Price']
    ],
    CUSTOMER_LEVELS: [
        [1, 'Platinum'],
        [2, 'Gold'],
        [3, 'Silver'],
        [4, 'Bronze'],
        [5, 'List'],
        [14, 'Auer'],
        [19, 'Carrier West'],
        [23, 'Elite'],
        [24, 'Transit 2'],
        [25, 'Transit 1'],
        [26, 'Base Price']
    ],
    CUSTOMER_LEVELS_COMPANY: [
        [24, 'Transit 2'],
        [25, 'Transit 1'],
        [26, 'Base Price']
    ]
}
