<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="row">
                    <div class="col-xs-4 dashboard_md_text">
                        <label>Sales</label>
                    </div>
                    <div class="col-xs-4 dashboard_md_text number">${{ ytdSalesAmount }}</div>
                    <div class="col-xs-4 dashboard_md_text number" :class="(ytdSalesDiff > 0) ? 'text-success' : 'text-danger'">{{ ytdSalesDiff }}%</div>
                </div>
                <div class="row">
                    <div class="col-xs-4 dashboard_md_text">
                        <label>Quotes</label>
                    </div>
                    <div class="col-xs-4 number dashboard_md_text">${{ ytdQuotesAmount }}</div>
                    <div class="col-xs-4 number dashboard_md_text" :class="(ytdQuotesDiff > 0) ? 'text-success' : 'text-danger'">{{ ytdQuotesDiff }}%</div>
                </div>
                <div class="row">
                    <div class="col-xs-4 dashboard_md_text">
                        <label>Branches</label>
                    </div>
                    <div class="col-xs-4 number dashboard_md_text">{{ data.branch_count }}</div>
                </div>
                <div class="row">
                    <div class="col-xs-4 dashboard_md_text">
                        <label>Customers</label>
                    </div>
                    <div class="col-xs-4 number dashboard_md_text">
                        <router-link :to="{ path: '/rpt/user_rpt', query: { company_search: data.company_name, company_search_id: data.company_id, user_type_search: 1 }}" class="cdi_link">{{ data.customer_count }}</router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 dashboard_md_text">
                        <label>Prospects</label>
                    </div>
                    <div class="col-xs-4 number dashboard_md_text">
                        <router-link :to="{ path: '/rpt/user_rpt', query: { company_search: data.company_name, company_search_id: data.company_id, user_type_search: 9 }}" class="cdi_link">{{ data.prospect_count }}</router-link>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="chart_container dashboard_chart_container"><Chart v-bind:results="chartData" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "@/components/utils/Chart";
export default {
    name: "Dashboard",
    components: {
        Chart,
    },
    props: ['data'],
    computed: {
        chartData: function() {
            return { chartData: this.data.chart };
        },
        ytdSalesAmount: function() {
            let amount = 0;
            if (this.data.ytd_sales) {
                amount = Math.round(parseFloat(this.data.ytd_sales.amount));
                if (isNaN(amount)){
                    amount = 0;
                }
            }
            
            return amount.toLocaleString('en-US');
        },
        ytdSalesDiff: function() {
            let diff = 0;
            if (this.data.ytd_sales) {
                diff = this.data.ytd_sales.diff;
            }

            return diff;
        },
        ytdQuotesAmount: function() {
            let amount = 0;
            if (this.data.ytd_quotes) {
                amount = Math.round(parseFloat(this.data.ytd_quotes.amount));
                if (isNaN(amount)){
                    amount = 0;
                }
            }

            return amount.toLocaleString('en-US');
        },
        ytdQuotesDiff: function() {
            let diff = 0;
            if (this.data.ytd_quotes) {
                diff = this.data.ytd_quotes.diff;
            }

            return diff;
        },
    },
}
</script>

<style scoped>

</style>