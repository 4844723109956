<template>
    <div class="col-sm-12">
        <h3>History</h3>
        <ul class="history_list">
            <li v-for="(line, index) in historyData" v-bind:key="index">
                {{ line }}
            </li>
        </ul>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
export default {
    name: "History",
    props: ['history'],
    data() {
        return {
            state: store.state,
        }
    },
    computed: {
        historyData: function() {
            return (this.history && this.history.length) ? this.history : this.state.history.history
        }
    }
}
</script>

<style scoped>

</style>