import paymentCache from '@/cache/payment.cache.js';
import customerLevelCache from '@/cache/customerLevel.cache';
import companyCache from '@/cache/company.cache.js';

export const companyStore = {
    state: {
        name: 'company',
        title: 'Company',
        titlePlural: 'Companies',
        nav: {
            base: 'company',
            newView: true,
            views: [
                { view: 'dashboard', label: 'Dashboard', },
                { view: 'details', label: 'Details', },
                { view: 'itemDiscount', label: 'Item Discounts', },
                { view: 'volDiscount', label: 'Volume Discounts', },
                { view: 'newBranch', label: 'New Branch', },
                { view: 'billTo', label: 'Bill To', },
                { view: 'priceGroup', label: 'Pricing Groups', },
                { view: 'files', label: 'Files', },
                { view: 'history', label: 'History', },
            ],
        },
        search: {
            endPoint: 'companies',
            formFields: {
                companyName: {
                    val: '',
                    qsField: '[strings][company_name]',
                    label: 'Company',
                    component: 'SearchTextInput'
                },
                paymentTerm: {
                    val: 0,
                    qsField: '[numbers][p.payment_term_id]',
                    label: 'Payment Term',
                    component: 'SearchSelectInput',
                    options: paymentCache.TERMS,
                },
                priceLevel: {
                    val: 0,
                    qsField: '[numbers][l.price_level_id]',
                    label: 'Level',
                    component: 'SearchSelectInput',
                    options: customerLevelCache.CUSTOMER_LEVELS_LEGACY,
                },
                exemptFrom: {
                    val: '',
                    qsField: '[dates][tax_exempt_expire_from]',
                    label: 'Exempt From',
                    component: 'SearchDateInput',
                    toggleHide: true,
                    defaultVal: '',
                },
                exemptTo: {
                    val: '',
                    qsField: '[dates][tax_exempt_expire_to]',
                    label: 'Exempt To',
                    component: 'SearchDateInput',
                    toggleHide: true,
                    defaultVal: '',
                },
                taxExempt: {
                    val: 0,
                    qsField: '[isset][tax_exempt]',
                    label: 'Tax Exempt',
                    component: 'SearchCheckboxInput',
                    toggleHide: true,
                    defaultVal: 0,
                },
                notTaxExempt: {
                    val: 0,
                    qsField: '[isnot][tax_exempt]',
                    label: 'Not Tax Exempt',
                    component: 'SearchCheckboxInput',
                    toggleHide: true,
                    defaultVal: 0,
                },
                enabled: {
                    val: 1,
                    qsField: '[isnot][company_disable]',
                    label: 'Enabled Only',
                    component: 'SearchCheckboxInput',
                    toggleHide: true,
                    defaultVal: 1,
                },
                creditHold: {
                    val: 0,
                    qsField: '[isset][credit_hold]',
                    label: 'On Credit Hold',
                    component: 'SearchCheckboxInput',
                    toggleHide: true,
                    defaultVal: 0,
                },
                isContractor: {
                    val: 0,
                    qsField: '[isset][is_contractor]',
                    label: 'Contractor',
                    component: 'SearchCheckboxInput',
                    toggleHide: true,
                    defaultVal: 0,
                },
                searchIndex: {val: 0, qsField: '[search_index]'},
            },
            resultFields: {
                company_id: {header: 'ID', link: 'company'},
                company_name: {header: 'Company', link: ''},
                payment_term: {header: 'Terms', link: ''},
                price_level: {header: 'Level', link: ''},
                priority: {header: 'Priority', link: ''},
            }
        },
        object: {
            fields: [
                {
                    name: 'company_id',
                    label: 'Company ID',
                    component: 'DetailsStatic'
                },
                {
                    name: 'company_name',
                    label: 'Company',
                    component: 'DetailsText'
                },
                {
                    name: 'company_disable',
                    label: 'Disable',
                    component: 'DetailsCheckbox'
                },
                {
                    name: 'credit_hold',
                    label: 'Credit Hold',
                    component: 'DetailsCheckbox'
                },
                {
                    name: 'credit_hold_exempt',
                    label: 'Credit Hold Exempt',
                    component: 'DetailsCheckbox'
                },
                {
                    name: 'tax_exempt',
                    label: 'Tax Exempt',
                    component: 'DetailsCheckbox'
                },
                {
                    name: 'tax_exempt_expire',
                    label: 'Tax Exempt Expires',
                    component: 'DetailsDate'
                },
                {
                    name: 'price_level_id',
                    label: 'Price Level',
                    component: 'DetailsSelect',
                    options: customerLevelCache.CUSTOMER_LEVELS_COMPANY,
                },
                {
                    name: 'payment_term_id',
                    label: 'Terms',
                    component: 'DetailsSelect',
                    options: paymentCache.TERMS,
                },
                {
                    name: 'type',
                    label: 'Type',
                    component: 'DetailsSelect',
                    options: companyCache.TYPES,
                },
                {
                    name: 'priority',
                    label: 'Priority',
                    component: 'DetailsStatic'
                },
                {
                    name: 'company_shipping_co_id',
                    label: 'Default Shipper',
                    component: 'DetailsTypeahead',
                    displayField: 'company_shipping_co',
                    cdiType: 'shipping_co',
                },
                {
                    name: 'company_notes',
                    label: 'Notes',
                    component: 'DetailsTextarea'
                },
                {
                    name: 'customs_clearance',
                    label: 'Customs Clearance',
                    component: 'DetailsTextarea'
                },
                {
                    name: 'rebate',
                    label: 'Rebate',
                    component: 'DetailsCheckbox'
                },
                {
                    name: 'is_contractor',
                    label: 'Contractor',
                    component: 'DetailsCheckbox'
                },
            ]
        },
    }
}