<template>
    <div>
        <input type=button class="cdi_button btn btn-default" value="New Pricing Group" @click="showForm=true">
        <form>
            <div class="panel panel-default spacing_top" v-show="showForm">
                <div class="panel-heading"></div>
                <div class="row panel-body">
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Label</label>
                        <input type="text" v-model="formFields.label.val" class="form-control">
                    </div>
                    <div class="col-xs-12">
                        <input class="cdi_button btn btn-default" type="button" value="Save Pricing Group" @click="save">
                        <input type="button" value="Cancel" class="cdi_button btn btn-default" @click="clearForm">
                    </div>
                </div>
            </div>
        </form>
        <div class="results">
            <table class='table'>
                <thead>
                <tr>
                    <th>Label</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(pricingGroup, id) in pricingGroups" v-bind:key="id">
                    <td>{{ pricingGroup.label }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>

<script>
import {store} from "@/store/BusinessObject.store";
import helpers from "@/store/helpers";

export default {
    name: "PricingGroups",
    props: ['pricingGroups'],
    data() {
        return {
            showForm: false,
            formFields: {
                label: {val: '', qsField: '[pricing_group_label]'},
            }
        }
    },
    methods: {
        clearForm: function () {
            this.showForm = false;
            this.formFields.label.val = '';
        },
        save: function() {
            store.api('save_pricing_group', helpers.getQSPairs(this.formFields), 'object.data')
                .then(() => this.clearForm());
        },
    },
}
</script>

<style scoped>

</style>